<template>
  <div class="container-fluid px-xl-4 py-4">
    <div class="add-vehicle custom-card">
      <confirm-change-menu @close="conformDailog = false" :dialog="conformDailog" @confirm="goToNext()" />
      <div class="add-form">
        <form-wizard @on-complete="save">
          <tab-content title="Individual parts" :before-change="validateForm">
            <b-form>
              <b-row>
                <b-col class="col-sm-12">
                  <div><span id="msg"></span></div>
                  <div class="upload-images-wrapper row">
                    <div class="upload-images col-md-6">
                      <h3 class="mb-3">{{ $t('AddCar.upload_image') }} </h3>
                      <b-form-group id="input-group-01" label-for="input-01">
                        <image-uploaders id="fileInputFront" :quality="0.9" outputFormat="verbose" :preview=false
                          :className="['hidden']" @input="onFileChange($event)" @onUpload="onUpload($event)" ref="front"
                          :multiple="true" :maximum="imageTags.length - image.length">
                          <label for="fileInputFront" slot="upload-label" class="node-image-uploader">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M12 20H5C4.46957 20 3.96086 19.7893 3.58579 19.4142C3.21071 19.0391 3 18.5304 3 18V9C3 8.46957 3.21071 7.96086 3.58579 7.58579C3.96086 7.21071 4.46957 7 5 7H6C6.53043 7 7.03914 6.78929 7.41421 6.41421C7.78929 6.03914 8 5.53043 8 5C8 4.73478 8.10536 4.48043 8.29289 4.29289C8.48043 4.10536 8.73478 4 9 4H15C15.2652 4 15.5196 4.10536 15.7071 4.29289C15.8946 4.48043 16 4.73478 16 5C16 5.53043 16.2107 6.03914 16.5858 6.41421C16.9609 6.78929 17.4696 7 18 7H19C19.5304 7 20.0391 7.21071 20.4142 7.58579C20.7893 7.96086 21 8.46957 21 9V12.5M16 19H22M19 16V22"
                                stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                              <path
                                d="M9 13C9 13.7956 9.31607 14.5587 9.87868 15.1213C10.4413 15.6839 11.2044 16 12 16C12.7956 16 13.5587 15.6839 14.1213 15.1213C14.6839 14.5587 15 13.7956 15 13C15 12.2044 14.6839 11.4413 14.1213 10.8787C13.5587 10.3161 12.7956 10 12 10C11.2044 10 10.4413 10.3161 9.87868 10.8787C9.31607 11.4413 9 12.2044 9 13Z"
                                stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            <span class="upload-caption">{{ $t('AddCar.click_to_upload') }}</span>
                          </label>
                        </image-uploaders>
                      </b-form-group>
                      <div class="d-flex flex-wrap gap-10">
                        <span class="label label-danger">{{ $t('AddCar.upload_image_number') }}</span>
                        <span class="errMsg">
                          {{
                            !(this.image.find((val) => val.tag === 'front'))
                              ? $t("validations.required")
                              : ""
                          }}</span>
                      </div>
                      <div class="img-preview">
                        <div class="d-none img-loader">
                          <div class="vld-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" stroke="#4CAF50">
                              <g fill="none" fill-rule="evenodd">
                                <g transform="translate(1 1)" stroke-width="2">
                                  <circle stroke-opacity=".25" cx="18" cy="18" r="18"></circle>
                                  <path d="M36 18c0-9.94-8.06-18-18-18">
                                    <animateTransform attributeName="transform" type="rotate" from="0 18 18"
                                      to="360 18 18" dur="0.8s" repeatCount="indefinite"></animateTransform>
                                  </path>
                                </g>
                              </g>
                            </svg>
                          </div>
                        </div>
                        <div class="img-pre-box" v-for="i of this.image">
                          <!-- <span>{{ i.tag }}</span> -->
                          <div :id="i.tag" class="select-image select-image-div">
                            <img :class="i.tag" :src="i.url100" alt=''>
                          </div>
                          <a :id="i.tag + '-delicon'" class="delicon" data-tooltip="Delete" @click="deleteImage(i.tag)">
                            <svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M1.12136 1.12132L5.364 5.36396M5.364 1.12132L1.12136 5.36396" stroke="red"
                                stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <h3 class="mb-3">{{ $t('AddCar.upload_video') }} <span class="label label-full-danger">{{ $t('AddCar.optional') }} </span></h3>
                      <b-form-group id="input-group-05" label-for="input-05">
                        <b-form-file id="input-video" accept=".3gp,.flv,.ogg,video/*" @change="onFileChangeVideo"
                          ref="fileupload">
                          <template #placeholder>
                            <div class="custom-placeholder">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M7 12H11M9 10V14M15 10L19.553 7.724C19.7054 7.64784 19.8748 7.61188 20.045 7.61955C20.2152 7.62721 20.3806 7.67825 20.5256 7.76781C20.6706 7.85736 20.7902 7.98248 20.8733 8.13127C20.9563 8.28007 20.9999 8.44761 21 8.618V15.382C20.9999 15.5524 20.9563 15.7199 20.8733 15.8687C20.7902 16.0175 20.6706 16.1426 20.5256 16.2322C20.3806 16.3218 20.2152 16.3728 20.045 16.3805C19.8748 16.3881 19.7054 16.3522 19.553 16.276L15 14V10ZM3 8C3 7.46957 3.21071 6.96086 3.58579 6.58579C3.96086 6.21071 4.46957 6 5 6H13C13.5304 6 14.0391 6.21071 14.4142 6.58579C14.7893 6.96086 15 7.46957 15 8V16C15 16.5304 14.7893 17.0391 14.4142 17.4142C14.0391 17.7893 13.5304 18 13 18H5C4.46957 18 3.96086 17.7893 3.58579 17.4142C3.21071 17.0391 3 16.5304 3 16V8Z"
                                  stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                              </svg>
                              <span class="upload-caption">{{ $t('AddCar.click_to_upload') }}</span>
                            </div>
                          </template>
                        </b-form-file>
                      </b-form-group>
                      <span class="label label-danger">{{ $t('AddCar.upload_video_validation') }}</span>
                      <div class="video-preview">
                        <div class="mr-2 d-none video-loader">
                          <div class="vld-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" stroke="#4CAF50">
                              <g fill="none" fill-rule="evenodd">
                                <g transform="translate(1 1)" stroke-width="2">
                                  <circle stroke-opacity=".25" cx="18" cy="18" r="18"></circle>
                                  <path d="M36 18c0-9.94-8.06-18-18-18">
                                    <animateTransform attributeName="transform" type="rotate" from="0 18 18"
                                      to="360 18 18" dur="0.8s" repeatCount="indefinite"></animateTransform>
                                  </path>
                                </g>
                              </g>
                            </svg>
                          </div>
                        </div>
                      </div>
                      <div class="video-pre-box">
                        <div id="video" class="select-image video-image"></div>
                        <video name="video" style="display: none;" id="video-tag"></video>
                        <a v-if="vehicleVideo && vehicleVideo.data" class="delicon" data-tooltip="Delete"
                          @click="deleteImage('video')">
                          <svg data-v-74177b85="" width="7" height="7" viewBox="0 0 7 7" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path data-v-74177b85="" d="M1.12136 1.12132L5.364 5.36396M5.364 1.12132L1.12136 5.36396"
                              stroke="red" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                          </svg>
                        </a>
                      </div>
                    </div>
                  </div>
                </b-col>
              </b-row>
              <div class="custom-form c-vehicle-vin">
                <h3 class="mt-4 mb-3">{{ $t('AddCar.add_part_heading') }}
                <span class="label label-full-danger">{{ $t('AddCar.add_part_heading_hint') }} </span></h3>
              </div>
              <div class="c-vehicle-vin-wrap">
                <b-row>
                  <b-col class="col-lg-4 col-md-6 col-sm-12 col-xs-12" v-if="onReader">
                    <StreamBarcodeReader @decode="onDecode"></StreamBarcodeReader>
                  </b-col>
                  <b-col class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                    <b-form-group id="input-group-0" :label="$t('AddCar.vin_field')" label-for="input-0">
                      <b-form-input id="input-0" type="text" maxlength="17" :placeholder="$t('AddCar.vin_field')" v-model="data.vin" @input="
                        $v.data.vin.$touch();
                      getVinSearchData(data.vin);
                      " @blur="$v.data.vin.$touch()" required :class="this.$v.data.vin.$dirty &&
                        $v.data.vin.$invalid
                        ? 'uppercase-text error-message'
                        : 'uppercase-text '
                        "></b-form-input>
                      <span class="errMsg"> {{ vinErrors[0] }}</span>
                    </b-form-group>
                  </b-col>
                  <b-col class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                    <b-form-group id="input-group-2" :label="$t('AddCar.year')" label-for="input-2">
                      <v-select id="input-2" :options="yearList" class="form-dropdown" :placeholder="$t('AddCar.year')"
                        v-model="data.year" @input="
                          $v.data.year.$touch();
                        onChangeYear();
                        " @blur=" $v.data.year.$touch(); $v.data.make.$touch()" required :class="this.$v.data.year.$dirty && $v.data.year.$invalid
                          ? 'error-message'
                          : ''
                          "></v-select>
                      <span class="errMsg"> {{ yearErrors[0] }}</span>
                    </b-form-group>
                  </b-col>
                  <b-col class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                    <b-form-group id="input-group-3" :label="$t('AddCar.make')" label-for="input-3">
                      <v-select :disabled="!data.year" id="input-3" :options="makeList" class="form-dropdown"
                        :placeholder="$t('AddCar.make')" v-model="data.make" item-text="label" item-value="MfrName" @input="
                          $v.data.make.$touch();
                        changeMakeOption();
                        " @blur="$v.data.make.$touch()" required :class="(!data.make && data.year && data.vin) || (this.$v.data.make.$dirty && $v.data.make.$invalid)
                          ? 'error-message'
                          : ''
                          "></v-select>
                      <span class="errMsg"> {{ makeErrors[0] }}</span>
                    </b-form-group>
                  </b-col>
                  <b-col class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                    <b-form-group id="input-group-3" :label="$t('AddCar.model')" label-for="input-3">
                      <v-select :disabled="!data.make" id="input-4" :options="modelList" class="form-dropdown"
                        :placeholder="$t('AddCar.model')" v-model="data.model" item-text="label" item-value="ModelNm" @input="
                          $v.data.model.$touch();
                        " @blur="$v.data.model.$touch()" required :class="((!data.model && data.make && data.year && data.vin) || (this.$v.data.model.$dirty && $v.data.model.$invalid))
                          ? 'error-message'
                          : ''
                          "></v-select>
                      <span class="errMsg"> {{ modelErrors[0] }}</span>
                    </b-form-group>
                  </b-col>
                  <b-col class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                    <b-form-group id="input-group-5" :label="$t('AddCar.vehicle_type')" label-for="input-5">
                      <b-form-input id="input-5" type="text" :placeholder="$t('AddCar.vehicle_type')"
                        v-model="data.vehicle_type"></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                    <b-form-group id="input-group-6" :label="$t('AddCar.body_type')" label-for="input-6">
                      <b-form-input id="input-6" type="text" :placeholder="$t('AddCar.body_type')"
                        v-model="data.body_type"></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                    <b-form-group id="input-group-7" :label="$t('AddCar.doors')" label-for="input-7">
                      <b-form-input id="input-7" type="number" max="10" min="0" :placeholder="$t('AddCar.doors')"
                        v-model="data.doors"></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                    <b-form-group id="input-group-8" :label="$t('AddCar.engine_name')" label-for="input-8">
                      <b-form-input id="input-8" type="text" :placeholder="$t('AddCar.engine_name')"
                        v-model="data.engine_name"></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col class="col-lg-4 col-md-6 col-sm-12 col-xs-12" style="display: none;">
                    <b-form-group id="input-group-9" :label="$t('AddCar.engine_id')" label-for="input-9">
                      <b-form-input id="input-9" type="text" :placeholder="$t('AddCar.engine_id')"
                        v-model="data.engine_id"></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col class="col-lg-4 col-md-6 col-sm-12 col-xs-12" style="display: none;">
                    <b-form-group id="input-group-10" :label="$t('AddCar.manufacturer')" label-for="input-10">
                      <b-form-input id="input-10" type="text" :placeholder="$t('AddCar.manufacturer')"
                        v-model="data.country_of_manufacture"></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col class="col-lg-4 col-md-6 col-sm-12 col-xs-12" style="display: none;">
                    <b-form-group id="input-group-10" :label="$t('AddCar.transmission_id')" label-for="input-10">
                      <b-form-input id="input-10" type="text" :placeholder="$t('AddCar.transmission_id')"
                        v-model="data.transmission_id"></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col class="col-lg-4 col-md-6 col-sm-12 col-xs-12" style="display: none;">
                    <b-form-group id="input-group-14" :label="$t('AddCar.drive_type')" label-for="input-14">
                      <b-form-input id="input-14" type="text" :placeholder="$t('AddCar.drive_type')"
                        v-model="data.drive_type"></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                    <b-form-group id="input-group-10" :label="$t('AddCar.color')" label-for="input-10">
                      <b-form-select id="input-10" type="text" class="form-control" :options="colors"
                        v-model="data.color" :placeholder="$t('AddCar.color')"></b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                    <b-form-group id="input-group-10" :label="$t('AddCar.miles')" label-for="input-10">
                      <b-form-input id="input-10" type="number" :placeholder="$t('AddCar.miles')" v-model="data.miles"
                        @input="$v.data.miles.$touch()" @blur="$v.data.miles.$touch()" required :class="this.$v.data.miles.$dirty && $v.data.miles.$invalid
                          ? 'error-message'
                          : ''
                          "></b-form-input>
                      <span class="errMsg"> {{ milesErrors[0] }}</span>
                    </b-form-group>
                  </b-col>
                  <b-col class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                    <b-form-group id="input-group-11" :label="$t('AddCar.wheel_size')" label-for="input-11">
                      <b-form-input id="input-11" type="text" v-model="data.wheels_size"
                        :placeholder="$t('AddCar.wheel_size')"></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                    <b-form-group id="input-group-10" :label="$t('AddCar.license_plate')" label-for="input-10">
                      <b-form-input id="input-10" type="text" v-model="data.license_plate" :placeholder="$t('AddCar.license_plate')"
                        class="uppercase-text"></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                    <b-form-group id="input-group-10" label-for="input-10">
                      <label>
                        {{ $t('AddCar.purchase_price') }}<span class="label label-danger">{{ $t('AddCar.purchase_price_tips') }}</span>
                      </label>
                      <b-input-group>
                        <b-input-group-prepend>
                          <span class="input-group-text">$</span>
                        </b-input-group-prepend>
                        <b-form-input id="input-10" type="number" :placeholder="$t('AddCar.purchase_price')"
                          v-model="data.purchaseprice" @input="" @keypress="validCost($event)">
                        </b-form-input>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                    <b-form-group id="opacity-0" label="  " label-for="input-10">
                      <b-form-group label="  " v-slot="{ ariaDescribedby }">
                        <label style="opacity: 0">
                          Purchase Price
                        </label>
                        <b-form-checkbox-group id="checkbox-group-1" v-model="selected" :options="optionscheckbox"
                          :aria-describedby="ariaDescribedby" name="flavour-1"></b-form-checkbox-group>
                      </b-form-group>
                    </b-form-group>
                  </b-col>
                  <b-col class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                    <b-form-group id="opacity-0" label="  " label-for="input-10">
                      <b-form-group label="  " v-slot="{ ariaDescribedby }">
                        <label style="opacity: 0">
                          Purchase Price
                        </label>
                        <b-form-checkbox id="checkbox-1" v-model="data.whole_car_available_for_sale">
                        {{ $t('AddCar.whole_car_sale') }} </b-form-checkbox>
                      </b-form-group>
                    </b-form-group>
                    <b-form-group id="input-group-10" :label="$t('AddCar.sale_price')" label-for="input-10"
                      v-if="data.whole_car_available_for_sale">
                      <b-input-group>
                        <b-input-group-prepend>
                          <span class="input-group-text">$</span>
                        </b-input-group-prepend>
                        <b-form-input id="input-10" type="number" :placeholder="$t('AddCar.sale_price')" v-model="data.sales_price"
                          @input="" @keypress="validSalesPrice($event)">
                        </b-form-input>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
              <h3 v-if="!$route.params.id" class="mt-4 mb-3">{{ $t('AddCar.quick_part_secltion') }}</h3>
              <div v-if="!$route.params.id" class="c-vehicle-vin-wrap">
                <b-row>
                  <b-col class="col-lg-12 col-md-12 col-sm-12 col-xs-12" v-if="!$route.params.id">
                    <b-form-group class="select-all-wrap">
                      <b-form-checkbox v-model="isCheckAllQuickParts" @change="onCheckAllQuickParts()">{{ $t('AddCar.select_all') }}</b-form-checkbox>
                    </b-form-group>
                    <b-form-group class="mb-0">
                      <ul class="check-listing">
                        <li v-for="part in allQuickParts" :key="part.id">
                          <b-form-checkbox class="custom-control-inline" v-model="data.parts"
                            @change="onQuickPartChange()" :value="part">{{ part.label }}</b-form-checkbox>
                        </li>
                      </ul>
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
              <h3 class="mt-4 mb-3">{{ $t('AddCar.description') }}</h3>
              <b-row>
                <b-col class="col-lg-12 col-md-12">
                  <b-form-group id="input-group-10" label-for="input-10">
                    <b-form-textarea id="textarea" v-model="data.notes" :placeholder="$t('AddCar.write_here')" rows="3"
                      max-rows="6"></b-form-textarea>
                    <span class="errMsg"> {{ notesErrors[0] }}</span>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
          </tab-content>
          <template slot="footer" slot-scope="props">
            <!-- <div class=wizard-footer-left>
              <wizard-button v-if="props.activeTabIndex > 0 && !props.isLastStep"
                :style="props.fillButtonStyle">Previous</wizard-button>
            </div> -->
            <div class="wizard-footer">
              <div class="d-flex" v-if="$route.params.id">
                <wizard-button @click.native="save('finish')"
                  class="btn custom-btn pattern-btn"><span>{{ $t('AddCar.update') }}</span></wizard-button>
              </div>
              <div class="d-flex flex-wrap gap-10" v-else>
                <span class="label label-danger">{{ $t('AddCar.submit_warning') }} </span>
                <wizard-button @click.native="save('finish')"
                  class="btn custom-btn pattern-btn"><span>{{ $t('AddCar.submit') }}</span></wizard-button>
              </div>
            </div>
          </template>
        </form-wizard>
      </div>
    </div>

    <div class="add-vehicle mt-5" v-if="$route.params.id">
      <confirmation-dialog @close="dialogDelete = false" :dialog="dialogDelete" @confirm="deleteCarParts()" />

      <edit-part-dialog @close="dialog = false" :dialog="dialog" :type="partType" :partId="selectedId"
        :carProfileId="selectedCarProfileId" :partNumber="selectedPartNumber" :partName="selectedPartName" />

      <print-part-barcode-dialog @close="printDialog = false" :printDialog="printDialog" :printPartId="printPartId"
        :printCarProfileId="printCarProfileId" />

      <image-viewer @close="dialogImage = false" :dialog="dialogImage" :images="partsImages" />
      <div class="row">
        <div class="col-md-12">
          <div class="page-header">
            <h3>{{ $t('partsList.part_listing_heading') }}</h3>
            <div class="add-btn" style="display: inherit;">
              <div style="margin-left:5px">
                <b-button class="btn custom-btn pattern-btn" data-tooltip="Add Parts"
                  :to='{ name: "Bulk Car Parts", query: { "isRedirect": true, "id": $route.params.id } }'
                  v-if="$route.params.id">
                  <span>{{ $t('partsList.add_parts') }}</span>
                </b-button>
              </div>
              <div style="margin-left:5px">
                <b-button class="btn custom-btn pattern-btn" data-tooltip="Bulk Delete" @click="dialogDelete = true">
                  <span>{{ $t('partsList.delete') }}</span>
                </b-button>
              </div>
              <div style="margin-left:5px">
                <b-button class="btn custom-btn pattern-btn" data-tooltip="Print Barcodes"
                  :to="{ name: 'Print All Barcode', params: { ids: $route.params.id, type: 'car_profile_id' }, query: { 'isRedirect': true } }">
                  <span>{{ $t('partsList.print_all') }}</span>
                </b-button>
              </div>
            </div>
          </div>
          <div class="custom-table">
            <div class="search-container">
              <h5 class="total-parts"></h5>
              <div class="search-group">
                <input type="text" id="dataTableSearch" ref="dataTableSearch" />
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M9 17C13.4183 17 17 13.4183 17 9C17 4.58172 13.4183 1 9 1C4.58172 1 1 4.58172 1 9C1 13.4183 4.58172 17 9 17Z"
                    stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M19 19L14.7 14.7" stroke="black" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round" />
                </svg>
              </div>
            </div>
            <table id="example" class="display dataTable nowrap responsive" cellspacing="0" width="100%">
              <thead>
                <tr>
                  <th class="desktop tablet-p tablet-l mobile-l mobile-p">
                    <b-form-group class="mb-0">
                      <b-form-checkbox v-model="isCheckAllParts"
                        @change="onCheckAllParts()"><span>Id</span></b-form-checkbox>

                    </b-form-group>
                  </th>
                  <th class="desktop">{{ $t('partsList.date') }}</th>
                  <th class="desktop tablet-p tablet-l mobile-l mobile-p">
                    {{ $t('partsList.part_name') }}
                  </th>
                  <th class="desktop">
                    {{ $t('AddCar.year') }} / {{ $t('AddCar.make') }}/ {{ $t('AddCar.model') }}/ {{ $t('AddCar.miles') }}
                  </th>
                  <th>Recommended<br /> {{ $t('partsList.sales') }} $</th>
                  <th>{{ $t('partsList.interchange') }} </th>
                  <th class="none">{{ $t('partsList.part_grade') }}</th>
                  <th class="none">{{ $t('partsList.inventory_days') }}</th>
                  <th class="none">{{ $t('partsList.warranty') }} </th>
                  <th class="none">{{ $t('partsList.description') }} </th>
                  <th class="desktop tablet-p tablet-l">{{ $t('partsList.action') }} </th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import {
  required,
  minLength,
  maxLength,
  between,
} from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";
import { showSuccess, showFailure } from "../eventbus/action.js";
import moment from "moment";
import { StreamBarcodeReader } from "vue-barcode-reader";
import Multiselect from 'vue-multiselect'
import ConfirmChangeMenu from "../components/ConfirmChangeMenu";
import ImageUploaders from "../components/ImageUploaders.vue";
import _ from 'lodash';
import { QUICK_ADD_PARTS, ITEMS_PER_PAGE } from "../constants/common";
import { PARTLIST } from "@/constants/apiUrls";
import ConfirmationDialog from "../components/ConfirmationDialog";
import ImageViewer from "../components/ImageViewer";
import EditPartDialog from "@/components/EditPartDialog";
import PrintPartBarcodeDialog from "../components/PrintPartBarcodeDialog";
import tokenService from "./../services/token.service.js";
import EventBus from "@/eventbus";

export default {
  components: {
    StreamBarcodeReader,
    Multiselect,
    ConfirmChangeMenu,
    ImageUploaders,
    ConfirmationDialog,
    ImageViewer,
    EditPartDialog,
    PrintPartBarcodeDialog
  },
  beforeRouteLeave(to, from, next) {
    if (to.query && to.query.isRedirect != true) {
      this.conformDailogOpen(next);
    } else {
      next();
    }
  },
  data() {
    return {
      modelList: [],
      makeList: [],
      yearList: [],
      partList: [],
      data: {
        make: null,
        make_code: null,
        model: null,
        model_code: null,
        year: null,
        trim: null,
        miles: null,
        vehicle_type: null,
        body_type: null,
        doors: null,
        country_of_manufacture: null,
        engine_name: null,
        engine_id: null,
        transmission_id: null,
        model_number: null,
        drive_type: null,
        color: null,
        purchaseprice: "",
        sales_price: "",
        whole_car_available_for_sale: null,
        parts: [],
        wheels_size: null,
      },
      formatted: "",
      selected: [], // Must be an array reference!
      colors: [
        { text: "Select", value: null },
        { text: "Beige", value: "Beige" },
        { text: "Black", value: "Black" },
        { text: "Blue", value: "Blue" },
        { text: "Brown", value: "Brown" },
        { text: "Gold", value: "Gold" },
        { text: "Gray", value: "Gray" },
        { text: "Green", value: "Green" },
        { text: "Maroon", value: "Maroon" },
        { text: "Orange", value: "Orange" },
        { text: "Pink", value: "Pink" },
        { text: "Purple", value: "Purple" },
        { text: "Red", value: "Red" },
        { text: "Silver", value: "Silver" },
        { text: "Tan", value: "Tan" },
        { text: "White", value: "White" },
        { text: "Yellow", value: "Yellow" },
      ],
      options: [
        { text: "Orange", value: "orange" },
        { text: "Apple", value: "apple" },
        { text: "Pineapple", value: "pineapple" },
        { text: "Grape", value: "grape" },
      ],
      optionscheckbox: [
        { text: "ABS", value: "abs" },
        { text: "A/C", value: "ac" },
        { text: "Key", value: "key" },
        { text: "Leather Seats", value: "leather_seats" },
      ],
      // allQuickPartsList: QUICK_ADD_PARTS,
      allQuickParts: [],
      isCheckAllQuickParts: false,

      front: "",
      back: "",
      right: "",
      left: "",
      image: [],
      vehicleVideo: null,
      onReader: false,
      conformDailog: false,
      imageTags: ['front', 'back', 'right', 'left', 'door_sticker', 'interior', 'open_hood', 'miscellaneous'],
      isDataSaved: false,

      isCheckAllParts: false,
      dialogDelete: false,
      dialog: false,
      partType: 'PartType',
      selectedId: '',
      selectedCarProfileId: '',
      selectedPartNumber: '',
      selectedPartName: '',
      printDialog: false,
      printPartId: '',
      printCarProfileId: '',
      dialogImage: false,
      partsImages: [],
    };
  },
  computed: {
    ...mapGetters({
      user: "user/getUser",
      yearOptions: "interchange/getYears",
      makeOptions: "interchange/getMake",
      modelOptions: "interchange/getModel",
      // pType: "partlist/getPType",
      partsSettings: "partlist/getPartsSettings",
      // imageResponse: "carlist/getImageResponse",
      mediaImageResponse: "partlist/getMediaImageResponse",
      // videoResponse: "carlist/getVideoResponse",
      mediaVideoResponse: "carlist/getMediaVideoResponse",
      vinSearch: "carlist/getVinSearchData",
      carDetailData: "carlist/getCarDetail",
      vehicleDetail: "carlist/getVehicleResponse",
    }),

    vinErrors() {
      const errors = [];
      if (!this.$v.data.vin.$dirty) return errors;
      !this.$v.data.vin.required &&
        errors.push(this.$t("validations.vinIsRequired"));
      !this.$v.data.vin.minLength &&
        errors.push(this.$t("validations.vinMinLength"));
      return errors;
    },
    milesErrors() {
      const errors = [];
      if (!this.$v.data.miles.$dirty) return errors;
      !this.$v.data.miles.between &&
        errors.push(this.$t("validations.milesIsBetween"));
      return errors;
    },
    makeErrors() {
      const errors = [];
      if (!this.$v.data.make.$dirty) return errors;
      !this.$v.data.make.required &&
        errors.push(this.$t("validations.makeIsRequired"));
      return errors;
    },
    modelErrors() {
      const errors = [];
      if (!this.$v.data.model.$dirty) return errors;
      !this.$v.data.model.required &&
        errors.push(this.$t("validations.modelIsRequired"));
      return errors;
    },
    yearErrors() {
      const errors = [];
      if (!this.$v.data.year.$dirty) return errors;
      !this.$v.data.year.required &&
        errors.push(this.$t("validations.yearIsRequired"));
      return errors;
    },
    trimErrors() {
      const errors = [];
      if (!this.$v.data.trim.$dirty) return errors;
      !this.$v.data.trim.required &&
        errors.push(this.$t("validations.trimIsRequired"));
      return errors;
    },
    vehicleTypeErrors() {
      const errors = [];
      if (!this.$v.data.vehicle_type.$dirty) return errors;
      !this.$v.data.vehicle_type.required &&
        errors.push(this.$t("validations.vehicleTypeIsRequired"));
      return errors;
    },
    bodyTypeErrors() {
      const errors = [];
      if (!this.$v.data.body_type.$dirty) return errors;
      !this.$v.data.body_type.required &&
        errors.push(this.$t("validations.bodyTypeIsRequired"));
      return errors;
    },
    driveTypeErrors() {
      const errors = [];
      if (!this.$v.data.drive_type.$dirty) return errors;
      !this.$v.data.drive_type.required &&
        errors.push(this.$t("validations.driveTypeIsRequired"));
      return errors;
    },
    doorsErrors() {
      const errors = [];
      if (!this.$v.data.doors.$dirty) return errors;
      !this.$v.data.doors.required &&
        errors.push(this.$t("validations.doorsIsRequired"));
      return errors;
    },
    comErrors() {
      const errors = [];
      if (!this.$v.data.country_of_manufacture.$dirty) return errors;
      !this.$v.data.country_of_manufacture.required &&
        errors.push(this.$t("validations.comIsRequired"));
      return errors;
    },
    engineNameErrors() {
      const errors = [];
      if (!this.$v.data.engine_name.$dirty) return errors;
      !this.$v.data.engine_name.required &&
        errors.push(this.$t("validations.engineNameIsRequired"));
      return errors;
    },
    engineIdErrors() {
      const errors = [];
      if (!this.$v.data.engine_id.$dirty) return errors;
      !this.$v.data.engine_id.required &&
        errors.push(this.$t("validations.engineIdIsRequired"));
      return errors;
    },
    transmissionIdErrors() {
      const errors = [];
      if (!this.$v.data.transmission_id.$dirty) return errors;
      !this.$v.data.transmission_id.required &&
        errors.push(this.$t("validations.transmissionIdIsRequired"));
      return errors;
    },
    colorErrors() {
      const errors = [];
      if (!this.$v.data.color.$dirty) return errors;
      !this.$v.data.color.required &&
        errors.push(this.$t("validations.colorIsRequired"));
      return errors;
    },
    purchasepriceErrors() {
      const errors = [];
      if (!this.$v.data.purchaseprice.$dirty) return errors;
      !this.$v.data.purchaseprice.required &&
        errors.push(this.$t("validations.purchasepriceIsRequired"));
      return errors;
    },
    notesErrors() {
      const errors = [];
      if (!this.$v.data.notes.$dirty) return errors;
      !this.$v.data.notes.maxLength &&
        errors.push(this.$t("validations.notesMaxLength"));
      return errors;
    },
  },
  validations: {
    data: {
      vin: { required, minLength: minLength(17), maxLength: maxLength(17) },
      year: { required },
      make: { required },
      model: { required },
      miles: {
        between: between(1, 1000000000),
      },
      notes: { maxLength: maxLength(150) },
    },
  },
  async mounted() {
    await this.setYear();
    await this.setMake();
    await this.setPType();
    await this.quickPartList();
    if (this.$route.params.id) {
      await this.fetchVehicleInfo(Number(this.$route.params.id));
      await this.getCarParts();
      await this.addListeners();
    }
    setTimeout(() => {
      $("li.car-wrapper > a").addClass(
        "router-link-exact-active router-link-active"
      );
    }, 10);

    EventBus.$on("resetDialog", () => {
      this.dialog = false;
      this.printDialog = false;
      this.selectedId = '';
      this.selectedPartNumber = '';
      this.selectedCarProfileId = '';
      this.selectedPartName = '';
      this.partType = 'PartType';
      this.printPartId = '';
      this.printCarProfileId = '';
    });
    EventBus.$on("getParts", () => {
      this.table.ajax.reload();
    });

    window.scrollTo({ top: 0, behavior: "smooth" });

    window.addEventListener('beforeunload', this.handleBeforeUnload);
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
  },
  destroyed() {
    $("li.car-wrapper > a").removeClass(
      "router-link-exact-active router-link-active"
    );
  },

  methods: {
    ...mapActions({
      getYears: "interchange/getYears",
      getMake: "interchange/getMake",
      getModel: "interchange/getModel",
      // getPType: "partlist/getPType",
      getPartsSetting: "partlist/getPartsSetting",
      // uploadImage: "carlist/uploadImage",
      uploadMediaImage: "partlist/uploadMediaImage",
      // uploadVideo: "carlist/uploadVideo",
      uploadMediaVideo: "carlist/uploadMediaVideo",
      addVehicle: "carlist/addVehicle",
      getVinSearch: "carlist/vinSearch",
      carDetail: "carlist/getCarDetail",
      updateVehicle: "carlist/updateVehicle",
      deleteParts: "partlist/deleteParts",
      deleteSelectedParts: "partlist/deleteSelectedParts",
    }),
    handleBeforeUnload(event) {
      if (!this.isDataSaved) {
        const confirmationMessage = this.$t("validations.confirmation_move");
        event.returnValue = confirmationMessage;
        return confirmationMessage;
      }
    },
    isLastStep() {
      if (this.$refs.wizard) {
        return this.$refs.wizard.isLastStep
      }
      return false
    },
    validateForm() {
      this.$v.data.$touch();
      if (!this.$v.data.$invalid) {
        return true;
      } else {
        return false;
      }
    },
    deleteImage(tag) {
      this.image = this.image.filter((item) => item.tag !== tag);
      if (tag == 'video') {
        $(`#${tag}`).empty();
        this.vehicleVideo = null;
      }
      // this.$refs[id].$el.children[1].value = "";
    },
    async onUpload(fileData) {
      if (!fileData.status) {
        showFailure(fileData.message);
      }
    },
    async onFileChange(fileData) {
      var type = null;
      var selectedTags = this.image.map(function (i) {
        return i.tag;
      });
      if (selectedTags.length != this.imageTags.length) {
        let difference = this.imageTags.filter(x => !selectedTags.includes(x));
        if (difference.length >= fileData.info.index + 1) {
          type = difference[fileData.info.index];
        } else {
          return;
        }

        var selectedImage = await this.saveImage(fileData.dataUrl, type);
        this.image = await this.image.filter((item) => item.tag !== type);
        await this.image.push(selectedImage.data);
      } else {
        var allowedImage = this.imageTags.length - selectedTags.length;
        if (allowedImage == 0) {
          showFailure(`You already selected all ${this.imageTags.length} images.`);
        }
      }
      // $(`#${type}`).empty();
      // $(`#${type}`).append(`<img class=${type} src='' alt=''>`);
      // $(`.${type}`).attr("src", selectedImage.data.url100);
      // $(`#${type}-delIcon`).show();
    },
    async onFileChangeVideo(e) {
      this.vehicleVideo = null;
      this.image = this.image.filter((item) => item.tag !== 'video');
      // create url to use as the src of the video
      const file = e.target.files[0];
      var fileURL = URL.createObjectURL(file);

      const size = (file.size / 1024 / 1024).toFixed(2);
      if (size > 500) {
        var message = "Video size cannot exceed more then 500MB";
        showFailure(message);
        return false;
      } else {
        this.vehicleVideo = await this.saveVideo(file, "video");
        if (this.vehicleVideo) {
          $("#video").empty();
          $("#video").append("<img class='video' src='' alt=''>");
          $(".video").attr("src", this.vehicleVideo.data.url100);
        }
      }
    },
    async saveVideo(file, tag) {
      try {
        // this.$store.commit("setLoader", true);
        $(".video-loader").removeClass('d-none');
        $(".wizard-btn").prop('disabled', true);
        let formData = new FormData();
        await formData.append("file", file);
        await formData.append("tag", tag);
        // await this.uploadVideo(formData);
        await this.uploadMediaVideo(formData);
        // showSuccess("Video Saved Successfully");
        // return this.videoResponse;
        return this.mediaVideoResponse;
        // this.data.media_ids = this.filelist.map((val) => val.data.id);
      } catch ({ message }) {
        const errorMessage =
          typeof message === "object" ? Object.values(message)[0][0] : message;
        showFailure(errorMessage);
      } finally {
        // this.$store.commit("setLoader", false);
        $(".video-loader").addClass('d-none');
        $(".wizard-btn").prop('disabled', false);
      }
    },
    async saveImage(file, tag) {
      try {
        // this.$store.commit("setLoader", true);
        $(".img-loader").removeClass('d-none');
        $(".wizard-btn").prop('disabled', true);
        let formData = new FormData();
        await formData.append("file", file);
        await formData.append("tag", tag);
        await formData.append("company_name", this.user.user_detail.company_name);
        // await this.uploadImage(formData);
        await this.uploadMediaImage(formData);
        // showSuccess("Image Saved Successfully");
        // return this.imageResponse;
        return this.mediaImageResponse;
        // this.data.media_ids = this.filelist.map((val) => val.data.id);
      } catch ({ message }) {
        // this.$refs[tag].$el.children[1].value = "";
        const errorMessage =
          typeof message === "object" ? Object.values(message)[0][0] : message;
        showFailure(errorMessage);
      } finally {
        // this.$store.commit("setLoader", false);
        $(".img-loader").addClass('d-none');
        $(".wizard-btn").prop('disabled', false);
      }
    },
    validCost(e) {
      const validate = this.data.purchaseprice.split(".");
      if (validate[1] && validate[1].length >= 2) {
        e.preventDefault();
      }
      const reg = /[^0-9]/;
      if (reg.test(e.key)) {
        e.preventDefault();
      }
    },
    validSalesPrice(e) {
      const validate = this.data.purchaseprice.split(".");
      if (validate[1] && validate[1].length >= 2) {
        e.preventDefault();
      }
      const reg = /[^0-9]/;
      if (reg.test(e.key)) {
        e.preventDefault();
      }
    },
    convertUSCurrency() {
      if (!this.data.purchaseprice) {
        return;
      }

      let currency = Number(
        this.data.purchaseprice.toString().replace(/[^0-9/.]/g, "")
      )
        .toString()
        .split(".");

      if (!currency[1]) {
        this.data.purchaseprice = currency[0]
          .replace(/\D/g, "")
          .split("")
          .reverse()
          .reduce(function (acc, num, i) {
            return num == "-" ? acc : num + (i && !(i % 3) ? "," : "") + acc;
          }, "");
      } else {
        this.data.purchaseprice =
          currency[0]
            .replace(/\D/g, "")
            .split("")
            .reverse()
            .reduce(function (acc, num, i) {
              return num == "-" ? acc : num + (i && !(i % 3) ? "," : "") + acc;
            }, "") +
          "." +
          currency[1];
      }
    },
    async onChangeYear() {
      this.data.make = null;
      this.data.model = null;
    },
    async changeMakeOption() {
      this.data.model = null;
      await this.setModel();
    },
    async setYear() {
      this.yearList = [];
      try {
        // this.$store.commit("setLoader", true);
        await this.getYears();
        this.yearList = await [...this.yearOptions];
      } catch ({ message }) {
        this.apiErrorMessage = message;
      } finally {
        // this.$store.commit("setLoader", false);
      }
    },
    async setMake() {
      this.makeList = [];
      try {
        // this.$store.commit("setLoader", true);
        await this.getMake({});
        this.makeList = await [...this.makeOptions];
      } catch ({ message }) {
        this.apiErrorMessage = message;
      } finally {
        // this.$store.commit("setLoader", false);
      }
    },
    async setModel() {
      this.modelList = [];
      try {
        // this.$store.commit("setLoader", true);
        await this.getModel({
          MfrCd: this.data.make.MfrCd,
        });
        this.modelList = await [...this.modelOptions];
      } catch ({ message }) {
        this.apiErrorMessage = message;
      } finally {
        // this.$store.commit("setLoader", false);
      }
    },
    async setPType() {
      try {
        // await this.getPType();
        await this.getPartsSetting();

        this.partList = [
          {
            label: 'Select All',
            options: this.partsSettings.map(function (val) {
              return {
                value: val.PartType,
                label: val.Description,
                id: val.id,
              };
            })
          }
        ];

      } catch ({ message }) {
        this.apiErrorMessage = message;
      }
    },
    async fetchVehicleInfo(id) {
      try {
        this.$store.commit("setLoader", true);
        await this.carDetail(id);
        this.data = Object.assign({}, this.carDetailData);

        //set selected parts
        // let selectedPartsIds = this.data.car_parts.map((val) => val.part_id != null && val.part_id.toString());
        // let selectedPartsNames = this.data.car_parts.map((val) => val.part_id != null && val.part_name);
        // this.data.parts = this.partList[0].options.filter((i) => {
        //   if(selectedPartsIds.includes(i.value) && selectedPartsNames.includes(i.label)) {
        //     return i;
        //   }
        // });

        this.data.parts = this.data.car_parts.map((val) => {
          return {
            value: val.part_id,
            label: val.part_name,
          }
        });

        this.data.year = parseInt(this.carDetailData.year);

        // const carlineMake = this.carDetailData.carline ? this.carDetailData.carline.MfrName : '';
        const carlineMake = this.carDetailData.make;
        let make = null;
        // const carlineModel = this.carDetailData.carline ? this.carDetailData.carline.ModelNm : '';
        const carlineModel = this.carDetailData.model;
        let model = null;

        this.makeList.map(function (element) {
          if (element.MfrName === carlineMake) {
            make = element;
          }
        });

        if (make) {
          this.data.make = make;

          await this.setModel();
          await this.modelList.map(function (element) {
            if (element.ModelNm === carlineModel) {
              model = element;
            }
          });

          if (model) {
            this.data.model = model;
          }
        }

        if (this.carDetailData.has_abs) {
          this.selected.push("abs");
        }
        if (this.carDetailData.has_ac) {
          this.selected.push("ac");
        }
        if (this.carDetailData.has_keys) {
          this.selected.push("key");
        }
        if (this.carDetailData.has_leather_seats) {
          this.selected.push("leather_seats");
        }

        this.image = [];
        if (this.data.medias && this.data.medias.length > 0) {
          this.data.medias.forEach(val => {
            this.image.push(val);
            $(`#${val.tag}`).append(`<img class='${val.tag}' src='' alt=''>`);
            $(`.${val.tag}`).attr("src", val.url100);

            if (val.tag === 'video') {
              this.vehicleVideo = { data: [val] };
            }
          });
        }
      } catch ({ message }) {
        const errorMessage =
          typeof message === "object" ? Object.values(message)[0][0] : message;
        showFailure(errorMessage);
      } finally {
        this.$store.commit("setLoader", false);
      }
    },
    async getVinSearchData(data) {
      try {
        if (data.length === 17) {
          this.$store.commit("setLoader", true);
          this.vinLoading = true;
          await this.getVinSearch({ vin: data });

          this.data.engine_number = this.vinSearch.engine_id;

          this.data.year = this.vinSearch.year;

          const vinSearchMake = this.vinSearch.make;
          let make = null;
          let vinSearchModel = null
          if (this.vinSearch.model && vinSearchMake == 'Tesla') {
            vinSearchModel = this.vinSearch.model;
          } else if (this.vinSearch.model) {
            vinSearchModel = this.vinSearch.model.split(" ")[0];
          }
          let model = null;

          this.makeList.map(function (element) {
            if (element.MfrName === vinSearchMake) {
              make = element;
            }
          });

          if (make) {
            this.data.make = make;

            await this.setModel();
            await this.modelList.map(function (element) {
              if (element.ModelNm === vinSearchModel.toUpperCase()) {
                model = element;
              }
            });

            if (model) {
              this.data.model = model;
            }
          }

          this.data.vehicle_type = this.vinSearch.vehicle_type;
          this.data.body_type = this.vinSearch.body_type;
          this.data.doors = this.vinSearch.doors;
          this.data.drive_type = this.vinSearch.drive_type;
          this.data.model_number = this.vinSearch.model_number;
          this.data.country_of_manufacture =
            this.vinSearch.country_of_manufacture;
          this.data.transmission_id = this.vinSearch.transmission_id;
          this.data.engine_id = this.vinSearch.engine_id;
          this.data.engine_name = this.vinSearch.engine_name;
        } else {
          this.data.engine_number = "";
          this.data.model = "";
          this.data.make = "";
          this.data.year = "";
          this.data.trim = "";
          this.data.vehicle_type = "";
          this.data.body_type = "";
          this.data.doors = "";
          this.data.country_of_manufacture = "";
          this.data.transmission_id = "";
          this.data.engine_id = "";
          this.data.engine_name = "";
          this.data.drive_type = "";
          this.data.model_number = "";
        }
      } catch ({ message }) {
        const errorMessage =
          typeof message === "object" ? Object.values(message)[0][0] : message;
        showFailure(errorMessage);
      } finally {
        this.$store.commit("setLoader", false);
      }
    },
    onContext() {
      this.formatted = this.data.license_expiration
        ? moment(this.data.license_expiration).format("MM/DD/YYYY")
        : "";
      // The date formatted in the locale, or the `label-no-date-selected` string
      console.log(this.formatted);
    },
    onDecode(result) {
      alert(result);
      // if (result.length === 17) {
      this.data.vin = result;
      this.getVinSearchData(this.data.vin);
      this.onReader = false;
      // }
    },
    onError(error) {
      console.log(error);
    },
    async save(button) {
      this.$v.data.$touch();

      if (!this.$v.data.$invalid) {
        try {
          this.$store.commit("setLoader", true);
          let payload = Object.assign({}, this.data);

          payload.make = this.data.make.MfrName;
          payload.make_code = this.data.make.MfrCd;
          payload.model = this.data.model.ModelNm;
          payload.model_code = this.data.model.ModelNm;

          payload.has_abs = this.selected.some((val) => val === "abs");
          payload.has_ac = this.selected.some((val) => val === "ac");
          payload.has_keys = this.selected.some((val) => val === "key");
          payload.has_leather_seats = this.selected.some((val) => val === "leather_seats");
          if (!this.image.find((val) => val.tag === "front")) {
            showFailure("Please upload front Image");
            return false;
          }
          var selectedMediaIds = _.cloneDeep(this.image);
          if (this.vehicleVideo && this.vehicleVideo.data) {
            selectedMediaIds.push(this.vehicleVideo.data);
          }

          payload.media_ids = selectedMediaIds.map((val) => val.id);
          payload.media_tags = selectedMediaIds.map((val) => val.tag);
          if (this.$route.params.id) {
            delete payload.car_parts;
            delete payload.medias;
            payload.id = this.$route.params.id;
            await this.updateVehicle({
              ...payload,
            });
          } else {
            await this.addVehicle({
              ...payload,
            });
          }
          // showSuccess("Vehicle Info Saved Successfully");
          if (button == 'next') {
            if (this.vehicleDetail) {
              this.$router.push({ name: "Bulk Car Parts", query: { isRedirect: true, id: this.vehicleDetail.id } });
            } else {
              this.$router.push({ name: "Car list", query: { isRedirect: true } });
            }
          } else {
            this.$router.push({ name: "Car list", query: { isRedirect: true } });
          }
        } catch ({ message }) {
          console.log('Error: ', message);
          const errorMessage =
            typeof message === "object"
              ? Object.values(message)[0][0]
              : message;
          showFailure(errorMessage);
        } finally {
          this.$store.commit("setLoader", false);
        }
      }
    },
    conformDailogOpen(next) {
      this.conformDailog = true;
      this.link = next;
    },
    goToNext() {
      this.conformDailog = false;
      this.link();
    },
    selectionChange() {
      this.$forceUpdate();
    },
    async quickPartList() {
      this.allQuickParts = await this.partsSettings.map(p => { return {'label': p.Description, 'value': p.PartType} });
    },
    onCheckAllQuickParts() {
      this.data.parts = [];
      if (this.isCheckAllQuickParts) {
        this.data.parts = this.allQuickParts;
      }
    },
    onQuickPartChange() {
      if (this.data.parts.length != 0 && this.data.parts.length == this.allQuickParts.length) {
        this.isCheckAllQuickParts = true;
      } else {
        this.isCheckAllQuickParts = false;
      }
    },
    async addListeners() {
      const self = this;
      $("#example").on("click", ".view-icon", function (e) {
        self.dialogImage = true;
        let url = e.currentTarget.dataset.mediaurl.split(",");
        self.partsImages = [...url];
        // let id = $(".view-icon").data("secret");
        // self.$router.push({ name: "Car Detail", params: { id: Number(id) } });
        e.preventDefault();
      });

      $("#example").on("click", ".edit-icon", function (e) {
        let id = e.currentTarget.dataset.secret;
        let part_number = e.currentTarget.dataset.part_number;
        let car_profile_id = e.currentTarget.dataset.car_profile_id;
        let part_name = e.currentTarget.dataset.part_name;
        // self.$router.push({
        //   name: "Update Vehicle Part",
        //   params: { uid: Number(id) },
        // });

        // console.log(id)
        self.selectedId = id;
        self.selectedPartNumber = part_number;
        self.selectedCarProfileId = car_profile_id;
        self.selectedPartName = part_name;
        self.partType = '';
        // console.log(self.selectedId)

        self.dialog = true;
        e.preventDefault();
      });

      $("#example").on("click", ".delete-icon", function (e) {
        self.selectedDeleteId = e.currentTarget.dataset.secret;
        self.dialogDelete = true;
        e.preventDefault();
      });

      $("#example").on("click", ".print-icon", function (e) {
        let partId = e.currentTarget.dataset.secret;
        let partNumber = e.currentTarget.dataset.partnumber;
        let car_profile_id = e.currentTarget.dataset.profile;

        self.printPartId = partId;
        self.printCarProfileId = car_profile_id;
        console.log('print..', self.printPartId, self.printCarProfileId);

        self.printDialog = true;
        e.preventDefault();
      })
    },
    async deleteCarParts() {
      try {
        this.$store.commit("setLoader", true);
        this.selectedPartIds = await ($(".select-checkbox input[type=checkbox]:checked").map(function (i, checkbox) { return checkbox.value; })).toArray();
        if (this.selectedDeleteId) {
          await this.deleteParts(this.selectedDeleteId);
        } else if (this.selectedPartIds.length > 0) {
          await this.deleteSelectedParts({ ids: this.selectedPartIds });
        } else {
          this.dialogDelete = false;
          showFailure("Please select atleast one part to delete");
          return;
        }
        this.table.ajax.reload();
        this.selectedPartIds = [];
        this.isCheckAllParts = false;
        this.dialogDelete = false;
        this.selectedDeleteId = null;
        showSuccess("Parts deleted Successfully");
      } catch ({ message }) {
        console.log(message);
        const errorMessage =
          typeof message === "object" ? Object.values(message)[0][0] : message;
        showFailure(errorMessage);
      } finally {
        this.$store.commit("setLoader", false);
      }
    },
    onCheckAllParts() {
      if (this.isCheckAllParts) {
        $("th .custom-control-input").addClass("selected");
        $(".select-checkbox input[type=checkbox]").each(function () { this.checked = true; });
      } else {
        $(".select-checkbox input[type=checkbox]").each(function () { this.checked = false; });
        $("th .custom-control-input").removeClass("selected");
      }
    },
    async getCarParts() {
      let self = this;
      try {
        this.$store.commit("setLoader", true);

        this.table = $("#example").DataTable({
          ajax: {
            url:
              process.env.VUE_APP_API_HOST +
              PARTLIST +
              "?car_profile_id=" +
              this.$route.params.id,
            type: "GET",
            beforeSend: function (request) {
              request.setRequestHeader(
                "Authorization",
                `Bearer ${tokenService.getAccessToken()}`
              );
            },
          },
          drawCallback: function (settings) {
            $(".total-parts").html(
              `Total Parts <span>${settings.json.recordsTotal}</span>`
            );
          },
          columns: [
            {
              orderable: false,
              data: function (row) {
                return (`<form-group class="select-checkbox custom-control custom-control-inline custom-checkbox">
                            <input type="checkbox" 
                              value="${row.id}" 
                              name="selectedPartIds[]" 
                              class="custom-control-input"
                              id="checkbox-${row.id}"/>
                            <label for="checkbox-${row.id}" class="custom-control-label"></label>
                          </form-group><span class="collespe-wrap">${row.id}</span>`);
              },
            },
            {
              data: function (row) {
                return (
                  self.$options.filters.DateZoneFilter(
                    moment(row.created_at)
                  ) || "N/A"
                );
              },
            },
            {
              orderable: true,
              data: function (full) {
                return full.part_name || "N/A";
              },
            },
            {
              orderable: false,
              data: function (full) {
                return (
                  full.year +
                  "/" +
                  full.make +
                  "/" +
                  full.model +
                  (full.miles ? "/" + (
                    Number(full.miles)
                      .toFixed(0)
                      .toString()
                      .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
                  ) : '')
                );
              },
            },

            {
              data: function (row) {
                return (
                  "$ " +
                  Number(row.recommended_sale_price)
                    .toFixed(2)
                    .toString()
                    .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
                );
              },
              orderable: true,
            },
            {
              orderable: false,
              data: function (full) {
                return full.interchange_code || "N/A";
              },
            },
            {
              orderable: false,
              data: function (full) {
                return full.part_grade || "N/A";
              },
            },
            {
              orderable: false,
              data: function (full) {
                return full.inventory_days || "N/A";
              },
            },
            {
              orderable: false,
              data: function (full) {
                return full.warranty_days || "N/A";
              },
            },
            {
              orderable: false,
              data: function (full) {
                return full.description
                  ? `<span class="description" title=${full.description}>${full.description}</span>`
                  : "N/A";
              },
            },
            {
              className: "action",
              orderable: false,
              data: function (row) {
                return `<ul class="action-list">
                    <li>
                      <a
                        class="edit-btn act-icon view-icon tooltips"
                        data-tooltip= "Part Images"
                        data-secret = ${row.id}
                        data-mediaurl = ${row.medias.map((val) => val.url500)}>
<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1 13L5.586 8.414C5.96106 8.03906 6.46967 7.82843 7 7.82843C7.53033 7.82843 8.03894 8.03906 8.414 8.414L13 13M11 11L12.586 9.414C12.9611 9.03906 13.4697 8.82843 14 8.82843C14.5303 8.82843 15.0389 9.03906 15.414 9.414L17 11M11 5H11.01M3 17H15C15.5304 17 16.0391 16.7893 16.4142 16.4142C16.7893 16.0391 17 15.5304 17 15V3C17 2.46957 16.7893 1.96086 16.4142 1.58579C16.0391 1.21071 15.5304 1 15 1H3C2.46957 1 1.96086 1.21071 1.58579 1.58579C1.21071 1.96086 1 2.46957 1 3V15C1 15.5304 1.21071 16.0391 1.58579 16.4142C1.96086 16.7893 2.46957 17 3 17Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg></a>
                    </li>
                    <li>
                      <router-link
                        class="edit-btn act-icon edit-icon tooltips"
                        data-tooltip="Edit"
                        data-secret = ${row.id}
                        data-part_number = ${row.part_number}
                        data-car_profile_id = ${row.car_profile_id}
                        data-part_name = "${row.part_name}"
                        :to="{ name: 'Edit Part', params: { id: item.id } }"
                      >
                        
<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.57012 3.88944L10.1106 6.42989M9.20334 1.6514L2.33082 8.52392C1.97572 8.87853 1.73354 9.33032 1.63481 9.82235L1 13L4.17766 12.364C4.66966 12.2656 5.12087 12.0244 5.47608 11.6692L12.3486 4.79666C12.5551 4.59014 12.7189 4.34496 12.8307 4.07513C12.9425 3.8053 13 3.51609 13 3.22403C13 2.93197 12.9425 2.64276 12.8307 2.37293C12.7189 2.1031 12.5551 1.85792 12.3486 1.6514C12.1421 1.44488 11.8969 1.28106 11.6271 1.16929C11.3572 1.05753 11.068 1 10.776 1C10.4839 1 10.1947 1.05753 9.92487 1.16929C9.65504 1.28106 9.40986 1.44488 9.20334 1.6514Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

                      </router-link>
                    </li>
                    <li>
                      <a
                        class=" edit-btn act-icon delete-icon tooltips"
                        data-secret = ${row.id}
                        data-tooltip="Delete"
                      >
                        

<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1 5H21M9 10V15M13 10V15M3 5H19L17.42 19.22C17.3658 19.7094 17.1331 20.1616 16.7663 20.49C16.3994 20.8184 15.9244 21 15.432 21H6.568C6.07564 21 5.60056 20.8184 5.23375 20.49C4.86693 20.1616 4.63416 19.7094 4.58 19.22L3 5ZM6.345 2.147C6.50675 1.80397 6.76271 1.514 7.083 1.31091C7.4033 1.10782 7.77474 0.999996 8.154 1H13.846C14.2254 0.999806 14.5971 1.10755 14.9176 1.31064C15.2381 1.51374 15.4942 1.80381 15.656 2.147L17 5H5L6.345 2.147Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>


                      </a>
                    </li>
                    <li>
                      <a
                        data-profiletype = ${row.profile_type}
                        data-partnumber = ${row.part_number}
                        data-profile = ${row.car_profile_id}
                        data-secret = ${row.id}
                        class="edit-btn act-icon print-icon tooltips"
                        data-tooltip="Print"
                      >
                        
<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.0611 10.0611H11.3556C11.6989 10.0611 12.0281 9.92473 12.2709 9.68198C12.5136 9.43922 12.65 9.10997 12.65 8.76667V6.17778C12.65 5.83447 12.5136 5.50522 12.2709 5.26247C12.0281 5.01971 11.6989 4.88333 11.3556 4.88333H2.29444C1.95114 4.88333 1.62189 5.01971 1.37913 5.26247C1.13638 5.50522 1 5.83447 1 6.17778V8.76667C1 9.10997 1.13638 9.43922 1.37913 9.68198C1.62189 9.92473 1.95114 10.0611 2.29444 10.0611H3.58889M10.0611 4.88333V2.29444C10.0611 1.95114 9.92473 1.62189 9.68198 1.37913C9.43922 1.13638 9.10997 1 8.76667 1H4.88333C4.54003 1 4.21078 1.13638 3.96802 1.37913C3.72527 1.62189 3.58889 1.95114 3.58889 2.29444V4.88333" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M3.58887 8.76661C3.58887 8.4233 3.72525 8.09406 3.968 7.8513C4.21076 7.60855 4.54 7.47217 4.88331 7.47217H8.76664C9.10995 7.47217 9.4392 7.60855 9.68195 7.8513C9.92471 8.09406 10.0611 8.4233 10.0611 8.76661V11.3555C10.0611 11.6988 9.92471 12.0281 9.68195 12.2708C9.4392 12.5136 9.10995 12.6499 8.76664 12.6499H4.88331C4.54 12.6499 4.21076 12.5136 3.968 12.2708C3.72525 12.0281 3.58887 11.6988 3.58887 11.3555V8.76661Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

                      </a>
                    </li>
                  </ul>`;
              },
            },
          ],
          order: [[2, 'asc']],
          pagingType: "simple_numbers",
          pageLength: ITEMS_PER_PAGE,
          serverSide: true,
          processing: false,
          dom: "Bfrtip",
          language: {
            search: '',
            searchPlaceholder: "Search..."
          },
          buttons: ["copy", "csv", "print"],
        });
        $.fn.dataTable.ext.errMode = "throw";
        // Move the search input to the custom input
        const searchInput = $(this.table.table().container()).find('.dataTables_filter input');
        $(this.$refs.dataTableSearch).replaceWith(searchInput);
      } catch ({ message }) {
        const errorMessage =
          typeof message === "object" ? Object.values(message)[0][0] : message;
        showFailure(errorMessage);
      } finally {
        this.$store.commit("setLoader", false);
      }
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
#msg {
  color: brown;
}

.multiselect-col>>>.multiselect__tags {
  border-radius: 0px !important;
  border: 1px solid #ced4da !important;
  min-height: 45px !important;
  display: block !important;
  width: 100% !important;
  height: calc(1.5em + .75rem + 2px) !important;
  padding: .375rem .75rem !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  color: #000 !important;
  background-color: #fff !important;
  background-clip: padding-box !important;
}

.multiselect-col>>>.multiselect.multiselect--disabled {
  background: var(--vs-disabled-bg) !important;
  cursor: var(--vs-disabled-cursor) !important;
  opacity: 1 !important;
  pointer-events: auto !important;
}

.select-image-div {
  max-width: 100px;
  max-height: 100px;
  min-height: 100px;
  min-width: 100px;
  display: flex;
  overflow: hidden;
}
</style>
